import { computed, nextTick, h } from 'vue'
import { transformImg, isString, getCookie } from '@shein/common-function'
import { getProductDetailUrl, getImageRatio, urlToRouteObject } from 'public/src/pages/goods_detail_v2/utils/common.js'
import { addProductDetailBfCache, getMainImg, getSecondImg, reportMetricCount, getRealShowImg } from '../js/utils'
import { clickAddBagAna, clickProductItem } from '../analysis'
import { markPoint } from 'public/src/services/mark/index.js'
import { setVisitorLabelAna } from '../components/ProductCardSlots/VisitorLabel'
import { setLabelCarouselAna } from 'public/src/pages/components/product/item_v3/components/ProductCardSlots/ProductLabelCarousel/index.js'
import { getLabelCarousel } from 'public/src/pages/components/product/item_v3/components/ProductCardSlots/ProductLabelCarousel/index.js'

import { controlBeltPrice } from '../js/utils'
import { controlItemLabels } from '../components/ProductCardLabelsControl'
import { ProductCardPriceAndDiscountInfo } from '../js/ProductCardPriceAndDiscountInfo/index.js'
import { setRouterCopy } from 'public/src/pre_requests/utlis'
import { ClientOnly } from '@sheinfe/vue-client-only'
import TMG from '@shein/time-management-guru'
import { getQueryString } from '@shein/common-function'
import { isUseBffFs } from 'public/src/pages/goods_detail_v2/utils/fs.js'

// components
import ProductCardPrices from '../components/ProductCardPrice/ProductCardPrices.vue'
import ProductItemSoldout from '../components/ProductItemSoldout.vue'
import ProdcutItemFeedback from '../components/ProdcutItemFeedback.vue'
import ProductItemOperationPanel from '../components/ProductItemOperationPanel.vue'
import { ProductCardImg } from '../components/ProductCardImg'
import { ProductCardMaskLayer } from '../components/ProductCardMaskLayer'
import ProductCardAutoplayVideo from '../components/ProductCardAutoplayVideo'
import PlayVideoIcon from '../components/UI/PlayVideoIcon.vue'
import { ProductCardImgRightBottom } from '../components/ProductCardImgRightBottom'
import { ProductCardImgBottom } from '../components/ProductCardImgBottom'
import { ProductCardAddBagBtn } from '../components/ProductCardAddBagBtn'
import { ProductCardStoreLink } from '../components/ProductCardStoreLink'
import ProductCardLabelsContainer from '../components/ProductCardLabels'
import ProductCardSellingPropositionContainer from '../components/ProductCardSellingProposition'
import ProductCardHorseRaceLabelContainer from '../components/ProductCardSellingProposition/ProductCardHorseRaceLabelContainer.vue'
import ProductItemTitleContainer from '../components/ProductCardTitle/ProductItemTitleContainer.vue'
import ProductCardBuyBoxEntrance from '../components/ProductCardBuyBoxEntrance/index.vue'
import ProductItemAddToBag from '../components/UI/ProductItemAddToBag.vue'
import { DeliveryWords } from '../components/DeliveryWords/index'
import SuggestedSalePrice from '../components/ProductCardPrice/SuggestedSalePrice.vue'
// analysis
import { daEventCenter } from 'public/src/services/eventCenter'

daEventCenter.addSubscriber({ modulecode: '2-3' })
daEventCenter.addSubscriber({ modulecode: '1-8-1' })
daEventCenter.addSubscriber({ modulecode: '1-7-1' })
daEventCenter.addSubscriber({ modulecode: '1-10-4' })
daEventCenter.addSubscriber({ modulecode: '1-10-2' })

const { IS_RW } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {

  /**
   * 商卡公共数据
   * @returns {import('./types').ProductCardProvide}
   */
  provide() {
    return {
      // 单列与多列取值key不同
      labelsFromKey: this.labelsFromKey,
      bffLabelsFromKey: this.bffLabelsFromKey,
      multiColumn: this.multiColumn,
      language: this.language,
      constantData: this.constantData,
      item: this.item,
      // 响应式的数据，后面慢慢将item取值替换为goodsInfo
      goodsInfo: computed(() => {
        return this.item
      }),
      config: this.config,
      index: this.index,
      setAttrForAnalysis: this.setAttrForAnalysis,
      supportVueDebug: this.supportVueDebug,
      finalPriceAndDiscountInfo: this.finalPriceAndDiscountInfo
    }
  },
  components: {
    ClientOnly,
    ProductCardPrices,
    ProdcutItemFeedback,
    ProductItemOperationPanel,
    ProductCardLabelsContainer,
    ProductCardSellingPropositionContainer,
    ProductCardHorseRaceLabelContainer,
    ProductItemTitleContainer,
    ProductItemSoldout,
    ProductCardImgRightBottom,
    ProductCardImgBottom,
    ProductCardAddBagBtn,
    ProductCardStoreLink,
    ProductItemAddToBag,
    ProductCardBuyBoxEntrance,
    ProductCardImg,
    ProductCardMaskLayer,
    ProductCardAutoplayVideo,
    PlayVideoIcon,
    DeliveryWords,
    SuggestedSalePrice
  },
  emits: [
    'mounted',
    'toggleWish',
    'clickItem',
    'exposedCard',
    'select',
    'longClick',
    'clickMoreBtn',
    'openQuickAdd',
    'clickCheckout',
    'openBuyBoxDrawer',
  ],
  /**
   * 相关 props 的注释信息维护到 types 中
   * @param {import('../types').ProductCardProps} props
   */
  props: {
    /**
     * @param {import('./types').ProductCardItem} item
     */
    item: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    },
    language: {
      type: Object,
      default: () => ({})
    },
    // 控制loading
    loading: {
      type: Boolean,
      default: false,
    },
    scrollTimers: {
      type: Number,
      default: 0
    },
    // 多语言
    // 延迟上报点击埋点
    delayClickAnalysis: {
      type: Boolean,
      default: false
    },
    /**
     * @param {import('./types').ProductCardConstantData} constantData
     */
    constantData: {
      type: Object,
      default: () => ({})
    },
    // 当前展示蒙层操作面板的角标
    curOperateIndex: {
      type: Number,
      default: -1
    },
    // @example '1-1'
    cropRate: {
      type: String,
      default: '',
    },
    showSelling: {
      type: Boolean,
      default: true,
    },
    // 进入选择模式
    showSelect: {
      type: Boolean,
      default: false
    },
    // 选中状态
    selectStatus: {
      type: Boolean,
      default: false
    },
    // checkbox显示位置
    // imgLeft 主图左上角
    // imgRight 主图右上角
    // mainLeft 商卡左边单独显示checkbox  只有单列商卡
    selectPosition: {
      type: String,
      default: 'imgRight'
    },
    focusSelectRange: {
      type: Boolean,
      default: false,
    },
    // 禁止选择
    selectDisable: {
      type: Boolean,
      default: false
    },
    // 调用的模块（慎用！尽量不要通过此字段来区分不同调用方，然后在商品项里面走不同分支逻辑！！不然后续维护比较重！！）
    moduleFrom: {
      type: String,
      default: ''
    },
    showSimilarPopup: {
      type: Boolean,
      default: false,
    },
    // 监控指标
    reportMetrics: {
      type: Object,
      default: () => ({
        jumpDetail: false, // 点击跳转商详
        addBag: false, // 点击加车按钮
        img: false, // 图片加载
      })
    },
    // LCP 方案
    lcp: Boolean,
    // 扩展跳转趋势词跳转频道落地页的参数
    extendToChannel: {
      type: Object,
      default() {
        return {}
      }
    },
    forceShowFlashSale: {
      type: Boolean,
      default: false
    },
  },
  data() {
    // 列数的配置， 有不同字号样式
    const columnNum = isString(this.column) ? Number(this.column.slice(0, -1)) : this.column
    this.multiColumn = columnNum > 2   // 是否多列商卡

    this.addToBagStyle = this.constantData?.GB_cssRight ? { position: 'absolute', left: '0.1067rem', bottom: '0.1067rem', right: 'auto' } : { position: 'absolute', right: '0.1067rem', bottom: '0.1067rem', left: 'auto' } // 兼容处理在某些账号ar互切情况下，收藏的降价模块中的加车按钮，addToBagStyle出现left, right同时存在造成样式偏移的问题

    this.IS_RW = IS_RW
    this.analysisWaitingQueue = []  // 埋点等待队列，在mounted需要设置的埋点属性放到这里等待mounted后设置 
    this.colors = []
    
    return {
      refreshListDebug: false, // 需要删除 // Eric TODO

      supportVueDebug: false, // cookie VueDebug是否开启
      longClickTimer: null, // 长按计时器
      status: {
        showFeedback: false,  // 显示负反馈弹窗
        showSelf: true,
        showOperationPanel: false // 操作面板展示
      },
      relateColorInfo: null, // 色块商品项数据
      cornerMarkFocus: false,
      decisionAttrVisible: false,
      imgRatio: '', // mainImg 图片宽高比
      relatedColor: [], // 色块图
      soldoutOperationPanelShow: false, //售罄状态下的操作面板展示

      finalPriceAndDiscountInfo: {},
      client: false, // 是否客户端渲染
    }
  },
  computed: {
    curData() {
      return this.item
    },
    slicedDecisionAttr() {
      return (this.curData.decisionAttrList || []).slice(0, 3)
    },
    decisionAttrAna() {
      return this.slicedDecisionAttr.map(item => `show_key_Attribute_${item.attrId}_${item['name-en']}`).join('|')
    },
    getMainImg() {
      const goodsImg = this.curData?.goods_img || ''
      return this.needShowSpuImg ? this.spuImg : goodsImg
    },
    saveAmount() {
      return !!this.item?.reducePrice?.amountWithSymbol ? this.item?.reducePrice?.amountWithSymbol : ''
    }
  },
  created() {
    const listRouteNames = ['product-list-v2', 'search-product-list', 'picked-info-list', 'feedback-rec-list']
    this.inProductList = listRouteNames.includes(this.$route?.name)
    this.relatedColorInit()

    const priceAndDiscountInfo = new ProductCardPriceAndDiscountInfo({
      source: 'list',
      goodsInfo: this.item, 
      language: this.language, 
      config: this.config,
      isPaid: !!this.config.showSheinClubPriceOnSale,
      multiColumn: this.multiColumn
    })
    
    this.finalPriceAndDiscountInfo = Object.assign(this.finalPriceAndDiscountInfo, {
      finalPrice: priceAndDiscountInfo.finalPrice,
      finalUnitPrice: priceAndDiscountInfo.finalUnitPrice,
      finalDiscount: priceAndDiscountInfo.finalDiscount,
      priceLabelText: priceAndDiscountInfo.priceLabelText,
      finalPriceType: priceAndDiscountInfo.finalPriceType,
      color: priceAndDiscountInfo.color,

      priceBottom: priceAndDiscountInfo.priceBottom,
      priceRight: priceAndDiscountInfo.priceRight,
      priceBottomRrp: priceAndDiscountInfo.priceBottomRrp,

      priceStateCollection: priceAndDiscountInfo.priceStateCollection,

      // 百亿补贴
      billionSubsidiesDiscount: priceAndDiscountInfo.billionSubsidiesDiscount,

      // 合规价
      suggestedSalePriceData: priceAndDiscountInfo.suggestedSalePriceData,
      finalPriceIsInversion: priceAndDiscountInfo.finalPriceIsInversion,
      finalRrpPriceIsInversion: priceAndDiscountInfo.finalRrpPriceIsInversion,
      isSpecialDeSuggested: priceAndDiscountInfo.isSpecialDeSuggested,
      isNormalSuggested: priceAndDiscountInfo.isNormalSuggested,
      isSpecialSuggested: priceAndDiscountInfo.isSpecialSuggested
    })
  },
  mounted() {
    // 调试，最终显示的价格文案
    if (gbCommonInfo?.isDebug) {
      const supportVueDebug = getCookie('vueDebug') || ''
      this.supportVueDebug = !!supportVueDebug
    }

    // 点刷调试代码
    // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
    if (Number(getCookie('refreshListDebug'))) {
      this.refreshListDebug = true
    }

    this.$emit('mounted', this)

    // 不可在挂在时加载图片比例信息，避免商品项图片懒加载功能失效
    // this.getMainImgRatio()

    this.client = true

    // 清空埋点队列
    this.clearAnalysisWaitingQueue()

    // 商卡实时曝光触发
    this.initIntersectionObserverInstance()
  },
  methods: {
    transformImg,
    // 初始化滚动可视化监听实例
    initIntersectionObserverInstance() {
      if (!this.config.enableObserve) return

      if (!this.IntersectionObserverInstance) {
        this.IntersectionObserverInstance = new IntersectionObserver((entries) => {
          const intersectionRatio = entries[0].intersectionRatio
          
          if (intersectionRatio > 0) {
            this.$emit('exposedCard', {
              goodsId: this.curData.goods_id,
              spuId: this.curData.productRelationID || ''
            })

            this.IntersectionObserverInstance.disconnect() // 断开之前的监听
            this.IntersectionObserverInstance = null
          }
        }, {
          thresholds: [0, 1]
        })
      }

      this.IntersectionObserverInstance.observe(this.$refs.productCardRef)
    },
    handleRender() {
      // config
      const { showSpuImg, showFeedback, showAddToBoardBtn, showFindSimilarBtn, showDeleteBtn, showPromoLabel, hideSoldOut, showCategoryViewStyle = false } = this.config || {}
      // 业务数据 预处理对象
      const pretreatInfo = this.pretreatInfo = this.curData.pretreatInfo || {}

      const { finalDiscount, suggestedSalePriceData } = this.finalPriceAndDiscountInfo ?? {}

      // 主图
      const getGoodsImg = this.curData?.goods_img || ''

      // spu 图
      const getSpuImage = (this.curData?.spu_image || [])[0]?.medium_image || ''

      // ai裁切图
      const getAICroppedImg = this.column == 2 && this.curData?.mobileVerticalView?.croppedImage?.url || ''

      this.goodsImg = getGoodsImg && getAICroppedImg ? getAICroppedImg : getGoodsImg
      this.spuImg = getSpuImage && getAICroppedImg ? getAICroppedImg : getSpuImage

      const spuImg = this.spuImg
      const needShowSpuImg = this.needShowSpuImg = !!(showSpuImg && spuImg && spuImg !== 'filteredSpuImg')
      const mainImg = needShowSpuImg ? spuImg : this.goodsImg

      // 主图需要剪裁
      const cccFeedback = this.curData?.cccFeedback || {}
      const hasFeedback = this.hasFeedback = cccFeedback.hasOwnProperty('negFeedbackInfo') && showFeedback

      // 商品名称
      const goodsName = this.curData?.goods_name || ''
      // ada
      const itemAdaOptions = this.cornerMarkFocus ? {} : {
        'tabindex': 0,
        'aria-label': goodsName,
        role: 'listitem'
      }

      // hot标, 1左上， 2名字前， 0不展示
      const showHotTag = this.showHotTag = this.curData?.tspLabels?.hot_color == 1 ? 1 : 0 // 1左上，2名字前，0不展示

      // 图片左上角ccc-tsp角标
      this.imgUpperLeft = pretreatInfo?.imgUpperLeft || null

      // 图片左下角ccc-tsp角标
      this.imgBottomLeft = pretreatInfo?.imgBottomLeft || null

      // 预售（coming soon）
      const comingSoon = +this.curData.new_product_unsale === 1
      // 售罄状态
      const isSoldOut = this.isSoldOut = this.curData.is_on_sale == 0 || this.curData.stock == 0 || this.curData.sale_percent == 1
      // 商铺信息
      const storeInfo = this.storeInfo = this.curData.storeInfo
      // 只展示品牌集成店
      const showStoreBar = this.config.showStoreBar && this.curData.storeInfo?.storeBusinessType === 2
      const showBtns = showAddToBoardBtn || showFindSimilarBtn || showDeleteBtn
      const showOperatinPanel = this.showOperatinPanel = showBtns && !this.showSelect && (!isSoldOut || hideSoldOut) || this.soldoutOperationPanelShow

      // 保留款
      const {
        beltLabel,
        showBestDeal,
        followLabelVisible
      } = controlBeltPrice(this.config, {
        finalDiscount: finalDiscount,
        pretreatInfo,  //goods商品项中预取的数据
        isSoldOut,
        viewsKey: this.labelsFromKey
      })

      this.followLabelVisible = followLabelVisible
      // 大促角标
      const promoLabelSrc = showPromoLabel && this.curData?.promoLabel?.src ? this.curData.promoLabel.src : ''

      // s3 vip价格
      const exclusivePromotionPrice = !suggestedSalePriceData?.type ? 
        (this.curData.exclusivePromotionPrice || null) : 
        null

      // 闪购
      const isFlashSale = !!pretreatInfo?.flashsale || this.forceShowFlashSale

      // 主图信息角标
      const labelsFromKey = this.labelsFromKey
      const locateLabels = this.curData[labelsFromKey]?.locateLabels || {}
      const { cornerPropertyUpsell, materialValueKey, isShowColorBlock } = locateLabels?.LOWER_RIGHT_LABEL || {}

      // 是否展示百亿补贴赛马轮播
      const show10BillionSubsidies = finalDiscount.show && this.config?.show10BillionSubsidies && pretreatInfo?.brandDealsAttributeLabel?.labelType === 'billion'
      // 是否设置右下角通用角标
      const hasLowerRightMaterial = !!materialValueKey
      // 展示通用角标色块优先
      const isShowLowerRightColorBlock = isShowColorBlock

      const brandDealsTitleLabel = this.curData?.pretreatInfo?.brandDealsTitleLabel?.[this.bffLabelsFromKey] || {}
      const show10BillionSubsidiesTitle = this.config?.show10BillionSubsidies && brandDealsTitleLabel?.labelType === 'billion'

      let showDeliveryWords = false
      // 满足百亿补贴的商品，要隐藏「趋势」和「店铺」标签
      if (this.config?.showDeliveryWords && !show10BillionSubsidiesTitle) {
        if (this.curData?.deliveryInfo?.display_title) {
          showDeliveryWords = true
        } else if (storeInfo?.isModStore && storeInfo.title) {
          showDeliveryWords = true
        } else if (storeInfo?.isChoiceStore && storeInfo.title) {
          showDeliveryWords = true
        } else if (this.curData?.deliveryInfo?.dpPoolId) {
          showDeliveryWords = true
        }
      }
      
      const {
        locateLabelsAna,
        ItemLocateLabels,
        ProductCardImgLeftTop,
        ProductCardImgRightTop,
        ProductCardImgLeftBottom,
      } = controlItemLabels(h, {
        beltLabel,
        goodsInfo: this.item,
        locateLabels,
        multiColumn: this.multiColumn,
        isFlashSale,
        pretreatInfo,
        constantData: this.constantData,
        config: this.config,
        language: this.language,
        curData: this.curData,
        finalPriceAndDiscountInfo: this.finalPriceAndDiscountInfo,
        promoLabelSrc,
        selectStatus: this.selectStatus,
        showSelect: this.showSelect,
        selectPosition: this.selectPosition,
        showHotTag,
        $slots: this.$slots,
        changeMarkFocus: (flag) => (this.cornerMarkFocus = flag),
        handleFocusCheckbox: () => {
          this.isFocusCheckbox = true // 点击checkbox时，标记为已聚焦
        }
      })

      this.locateLabelsAna = locateLabelsAna

      // 更多按钮
      const showMoreBtn = hasFeedback || ((!isSoldOut) && showOperatinPanel) || this.showSimilarPopup || (showCategoryViewStyle && !this.showSelect)

      this.priceStateCollection = {
        finalABPriceType: false, // 最终价格是否ab价， 1普通ab价  2付费会员ab价
        hasEstimatedPrice: false, // 是否有到手价并且满足门槛
      }

      const { hasEstimatedPrice } = this.finalPriceAndDiscountInfo?.priceStateCollection ?? {}

      // 增加驼峰价格显示的控制逻辑, 如果命中新到手价样式，且价格为到手价，会员价相关不展示驼峰(其他的价格是否驼峰根据abt配置来)
      const needShowCame = this.config?.showNewStyleEstimated && hasEstimatedPrice

      const showCamelPrice = (this.config.showCamelPrice ?? true) && !needShowCame

      return {
        itemAdaOptions,
        mainImg,
        showOperatinPanel,
        isSoldOut,
        showMoreBtn,
        hasFeedback,
        cccFeedback,
        comingSoon,
        showBestDeal,
        followLabelVisible,
        beltLabel,
        showStoreBar,
        exclusivePromotionPrice,
        storeInfo,
        showDeliveryWords,
        showCamelPrice,
        showSelling: this.showSelling || show10BillionSubsidies,
        getAICroppedImg,

        cornerPropertyUpsell,
        hasLowerRightMaterial,
        isShowLowerRightColorBlock,
        ItemLocateLabels,
        ProductCardImgLeftTop,
        ProductCardImgRightTop,
        ProductCardImgLeftBottom,
      }
    },
    relatedColorInit() {
      // 提取出色块图
      if (this.index < 20 || this.item.lazyClose) {
        // ssr 直出的图片(瀑布流是 6 个, 齐平式是 20 个), 由于不会触发 load 事件, 所以直接设置色块图
        // 预加载主图的商品项, 不做色块延迟加载处理
        this.relatedColor = this.getRelatedColor()
        return
      }
      // 这里开始是第二页, 也就是客户端渲染了
      this.colors = this.getRelatedColor()
    },

    getRelatedColor() {
      const labelsFromKey = this.labelsFromKey
      const locateLabels = this.curData[labelsFromKey]?.locateLabels
      const { materialValueKey, relatedColor } = locateLabels?.LOWER_RIGHT_LABEL || {}

      return materialValueKey === 'color_information' ? relatedColor || [] : []
    },

    setElAttr() {
      const pretreatInfo = this.curData?.pretreatInfo || {}
      const mixPromotionInfo = pretreatInfo?.mixPromotionInfo || []

      const { finalPriceType, priceStateCollection, priceBottomRrp } = this.finalPriceAndDiscountInfo ?? {}

      const promoLabelSrc = this.config.showPromoLabel && this.curData?.promoLabel?.src
      const isSoldOut = this.curData.is_on_sale == 0 || this.curData.stock == 0 || this.curData.sale_percent == 1
      // 到手价的一些信息
      const { estimatedPrice, satisfied, estimatedCouponTypeId } = this.curData.estimatedPriceInfo || {}
      const estimatedPriceUsdAmount = estimatedPrice?.usdAmount // 到手美元价
      const satisfiedVal = [2, 1][satisfied] || ''
      const isNewUserEstimatedPrice = [23, 56, 57, 65].includes(estimatedCouponTypeId) // 新客到手价[pageId=1340027622]
      const isNewCouponProduct = this.curData?.isNewCoupon
      const estimatedPriceWithSymbolVal = this.config.showEstimatedPriceOnSale && (satisfied === 1) && estimatedPrice?.amountWithSymbol
      const isShowNewuseronlyPriceLabel = this.config.showNewuseronlyPriceLabel && isNewUserEstimatedPrice && isNewCouponProduct && estimatedPriceWithSymbolVal // 当前为新客到手价 & 根据ab展示新客到手价说明

      const labelsFromKey = this.labelsFromKey
      let haveTopLeftBadge = this.curData?.[labelsFromKey]?.locateLabels?.UPPER_LEFT_LABEL ?? false //左上角是否有角标(3p)
      const dataShowLabelHot = ((this.config.showTitle && this.curData?.goods_name && !this.frontTitle?.code && this.showHotTag === 2) || (!this.imgUpperLeft?.code && !haveTopLeftBadge && this.showHotTag === 1)) ? 'show_label_hot' : '' // 3p > new > hot, 只有真正展示hot标才上报埋点
      // 31品类限定商家限时直降 wiki.pageId=1198803653
      let showSellerLimitedLabel = ''
      if (mixPromotionInfo.some(item => +item?.typeId === 31 && item?.promotion_logo_type === 3)) {
        showSellerLimitedLabel = 'show_seller_limited_label'
      }

      const aiCroppedTraceInfo = this.column == 2 && this.curData?.mobileVerticalView?.croppedImage?.appTraceInfo || ''

      // 图片
      this.mainImgForDetail = getMainImg(this.curData)
      this.secondImageForDetail = getSecondImg(this.curData)

      this.setAttrForAnalysis((el, isMainCtn) => {
        if (!this.config.useOwnClickExposeAnalysis) {
          el.setAttribute('da-event-click', !this.delayClickAnalysis ? (this.config.itemDAEventClickId || '2-3-1') : '')
          isMainCtn && el.setAttribute('da-event-expose', this.config.itemDAEventExposeId || '2-3-2')
        }
        const labelsFromKey = this.labelsFromKey

        // warning: 子组件还有部分埋点上报，搜索setAttrForAnalysis查看
        const extMarks = this.curData?.ext?.marks ? `marks_${this.curData.ext.marks}` : ''

        el.setAttribute('data-sku', this.curData?.goods_sn || '')
        el.setAttribute('data-spu', this.curData?.productRelationID || '')
        el.setAttribute('data-id', this.curData?.goods_id || '')
        el.setAttribute('data-name', this.curData?.goods_name || '')
        el.setAttribute('data-rec_mark', this.curData?.ext?.rec_mark || '')
        el.setAttribute('data-ext_marks', extMarks)
        el.setAttribute('data-extra_mark', this.curData?.ext?.extra_mark || '')
        el.setAttribute('data-other_ext_mark', this.curData?.ext?.other_ext_mark || '')
        el.setAttribute('data-other_d_ext_mark', this.curData?.dynamic_ext?.other_d_ext_mark || '')
        // 类目反馈组件测试用的
        // el.setAttribute('data-cat_name_temp', this.curData?.cat_name || '')
        // el.setAttribute('data-cat_id_temp', this.curData?.cat_id || '')
        // el.setAttribute('data-cat_id', this.curData?.cat_id || '')
        el.setAttribute('data-price', this.curData?.salePrice?.amount || '')
        el.setAttribute('data-us-price', this.curData?.salePrice?.usdAmount || '')
        el.setAttribute('data-us-origin-price', this.curData?.retailPrice?.usdAmount || '')
        el.setAttribute('data-index', this.index)
        el.setAttribute('data-type', this.curData?.dataSource || '') // config.moduleFrom == 'recommend'
        el.setAttribute('data-similar', isSoldOut && this.config.showSimilarBtn || '')
        el.setAttribute('data-reduce-price', !isSoldOut && pretreatInfo.reducePrice?.usdAmount || '')
        el.setAttribute('data-soldout', Number(isSoldOut)) // data-soldout 目前只有在最近页面有用到
        el.setAttribute('data-sold-out', Number(isSoldOut)) // data-sold-out 需要用到其他页面上，因为上报的标识不一样，需要另外设置一个属性
        el.setAttribute('data-show-exclusive-price', this.curData.exclusivePromotionPrice || '')
        el.setAttribute('data-lable', pretreatInfo.label || '')
        el.setAttribute('data-series-brand', !promoLabelSrc && pretreatInfo.seriesOrBrandAnalysis || '')
        el.setAttribute('data-video', this.curData?.video_url ? 'video_icon' : '')
        el.setAttribute('data-spu-img', this.spuImg ? (this.needShowSpuImg ? 'spupic_1' : 'spupic_0') : '') // 有spu图且展示则上报spupic_1，有spu图但不展示则上报spupic_0，无spu图不上报
        el.setAttribute('data-mall_tag_code', `${pretreatInfo.mallTagsInfo?.mall_tags || ''}_${this.curData?.mall_code || '-'}`)
        el.setAttribute('data-store_code', this.curData?.store_code ?? '')
        el.setAttribute('data-sale-attr', pretreatInfo.saleAttr?.analysis || '')
        el.setAttribute('data-show-promot-info', this.labelsShow?.promotionLabel || '')
        el.setAttribute('data-price-cut', pretreatInfo.priceCut || '')
        el.setAttribute('data-promotion-id', mixPromotionInfo.map(item => item?.id).join('`') || '')
        el.setAttribute('data-type-id', mixPromotionInfo.map(item => item?.typeId).join('`') || '')
        el.setAttribute('data-best-deal', pretreatInfo.showBestDeal ? 1 : '')
        el.setAttribute('data-follow-label', this.followLabelVisible ? 1 : '')
        el.setAttribute('data-promo-label', promoLabelSrc ? 1 : '')
        el.setAttribute('data-belt-label', pretreatInfo[labelsFromKey]?.beltLabel?.ana || '')
        el.setAttribute('data-decision-attr', this.decisionAttrVisible ? this.decisionAttrAna : '')
        el.setAttribute('data-show-label-hot', dataShowLabelHot)
        el.setAttribute('data-locate-labels', this.locateLabelsAna || '')
        el.setAttribute('data-user-preferences-label', pretreatInfo[labelsFromKey]?.sellingPointUniversalLabels?.[0]?.userPreferencesLabel?.ana || '')
        el.setAttribute('data-show-seller-limited-label', showSellerLimitedLabel)
        el.setAttribute('data-show-newuseronly-price-label', isShowNewuseronlyPriceLabel ? 'show_new_user_Price' : '')
        el.setAttribute('data-add-cart-type', this.curData?.checkoutNum ? 'add_cart_type_added' : 'add_cart_type_add')
        el.setAttribute('data-is-refresh', this.curData?.isRefresh ? 1 : 0)
        el.setAttribute('data-ai-cropped-img', aiCroppedTraceInfo || '')
        el.setAttribute('local_goods_id', this.curData?.local_goods_id || '')

        // 只有最终为售价并且是abt价才需要上报
        const hasAbPricePromition = this.curData?.promotionInfo?.find(item => +item?.typeId === 32)
        if (finalPriceType === 'salePrice' && hasAbPricePromition) {
          el.setAttribute('data-is-ab-price', +hasAbPricePromition.disableAbPriceEstimated === 1 || this.config.noNeedAnyEstimatedPriceTag ? 'estimated_price_3_noestimate' : 'estimated_price_3_estimate')
        }
        
        setVisitorLabelAna({ el, item: this.curData })
        setLabelCarouselAna({ el, item: this.curData })

        // 单件价
        if (priceStateCollection?.showUnitPrice) {
          const unitPriceAna = (this.curData?.unitBury || '').replace('{0}', this.finalPriceAndDiscountInfo?.finalUnitPrice?.usdAmount ?? '')
          el.setAttribute('data-unit-price', unitPriceAna)
        }

        if(this.item.isHypernymGoods) {
          el.setAttribute('data-hypernym-goods', 1)
        }
        const badges = []
        const dataBadges = el.getAttribute('data-badges')
        const goodsName = this.curData?.goods_name
        const upLeftBadges = goodsName && this.config.showTitle && this.frontTitle?.code || this.imgUpperLeft?.code
        if (dataBadges) badges.push(dataBadges)
        if (upLeftBadges) badges.push(upLeftBadges)
        el.setAttribute('data-badges', badges)

        if (satisfiedVal && estimatedPriceUsdAmount) {
          el.setAttribute('data-estimated-price', `${satisfiedVal}\`${estimatedPriceUsdAmount}`)
        }
        el.setAttribute('data-main-img', this.mainImgForDetail || '')
        el.setAttribute('data-second-img', this.secondImageForDetail || '')

        if (priceBottomRrp?.show) {
          el.setAttribute('data-rrp-price', 'list_rrp_price')
        }

        // 是否百补
        const isSubsidiesGoods = (this.curData?.promotionInfo || []).find(info => +info.typeId === 31 && +info.promotion_logo_type === 21)
        if (isSubsidiesGoods && this.config?.show10BillionSubsidies) {
          el.setAttribute('data-billion', 'brandDeals')
        }

        // 品类首金
        if (this.curData?.pretreatInfo?.brandDealsAttributeLabel?.labelType === 'userVoucher') {
          el.setAttribute('data-user-voucher', 'listnewuserVoucher')
        }

        // 趋势词相关 START
        const { trend_word_id, product_select_id, sellPointWordId, sellPointWordName } = this.curData?.deliveryInfo || {}
        const { isModStore, isChoiceStore, storeCode, title } = this.curData?.storeInfo  || {}
        if (trend_word_id) {
          const isTrendContent = el.classList?.contains('trend-list__product-card') ? product_select_id + '_' + 1 : 0
          el.setAttribute('data-trend-word-id', trend_word_id ? trend_word_id + '_' + isTrendContent : '')
          el.setAttribute('data-trend-product-select-id', product_select_id || '')
        } else if (isModStore || isChoiceStore) {
          const isFashionStore = el.classList?.contains('fashion-store__product-card') ? 1 : 0
          el.setAttribute('data-trend-shop-code', storeCode ? storeCode + '_' + title + '_' + isFashionStore + '_' + ((isChoiceStore & !isModStore) ? 1 : 0)  : '')
        } else if (sellPointWordId) {
          el.setAttribute('data-list-choice-word-id', sellPointWordId)
          el.setAttribute('data-list-choice-word-name', sellPointWordName)
        }
        // 趋势词相关 END
        el.setAttribute('data-divide-time', this.curData?.divideTime || '')

        // 点后推
        if (this.curData?.recommendCardData?.realtimePosition) {
          el.setAttribute('data-realtime-position', this.curData?.recommendCardData?.realtimePosition)
        }
        if (this.curData?.recommendCardData?.fromCateId) {
          el.setAttribute('data-realtime-from-cate-id', this.curData?.recommendCardData?.fromCateId)
        }
        if (this.curData?.recommendCardData?.fromGoodsId) {
          el.setAttribute('data-realtime-from-goods-id', this.curData?.recommendCardData?.fromGoodsId)
        }
        // cccx推荐点后刷
        if (this.curData?.recommendRefreshData) {
          el.setAttribute('data-recommend-refresh', this.curData.recommendRefreshData)
        }

        if (this.curData?.is_noactive_refresh) {
          el.setAttribute('data-is-noactive-refresh', 'is_noactive_refresh')
        }
        if (this.curData?.is_addcart_refresh) {
          el.setAttribute('data-is-addcart-refresh', 'is_addcart_refresh')
        }
        if (this.curData?.is_addwish_refresh) {
          el.setAttribute('data-is-addwish-refresh', 'is_addwish_refresh')
        }
        // 特殊强化样式
        if (this.curData?.is_colorful_type) {
          el.setAttribute('data-is-colorful-type', this.curData.is_colorful_type)
        }
      })

      // 已经执行过 setElAttr 记录
      this.setElAttrDone = true
    },

    /**
     * 自定义埋点字段
     * @param {Array} attrArr [{ attr: xxx, value: xxx }]
     */
    customizeElAttr(attrArr = []) {
      attrArr.forEach(({ attr, value }) => {
        this.setAttrForAnalysis((el) => {
          el.setAttribute(attr, value)
        })
      })
    },

    // 收藏/取消收藏
    handleToggleWish(payload) {
      this.$emit('toggleWish', payload)
    },
    handlePointerover() {
      const { imgSrc, showMarketingImg } = getRealShowImg({ item: this.curData, lazyLoadSrc: this.getLazyloadSrc() })
      if (showMarketingImg) {
        // console.log('start',Date.now())
        const img = new Image()
        img.src = imgSrc
      }
    },
    onImgLoadFinish (imgLoadType, e) {
      if (this.imgRatio) {
        return
      }

      // 延迟加载色块图
      if (this.colors?.length > 0) {
        this.relatedColor = this.colors
        this.colors = []
      }
      

      if (imgLoadType == 'succ') {
        const src = e.target.src
        getImageRatio(src).then((ratio) => {
          this.imgRatio = ratio
        })
      }
    },
    async clickItem(e, { from } = {}) {
      // 点击商品项，不跳商详的情况
      if (this.disableJumpDetail(e)) return
      markPoint('toNextPageClick', 'public')
      // 跳转商详
      let url = await this.handleGoodsUrl({ ...this.curData }, true)
      if ((!this.config.disableMainimgJump) && this.$route) {
        const routerObj = urlToRouteObject(url)
        if (routerObj) {
          // 临时存储，不带 imgRatio，会在 beforeEach 被带有 imgRatio 的 url 覆盖掉
          setRouterCopy(routerObj)
        }
        const is_use_bff_fs = isUseBffFs()
        const quickRequestNames = [`getGoodsDetail/${this.curData.goods_id}`, `getGoodsDetailRealTime/${this.curData.goods_id}`]
        if (is_use_bff_fs) {
          quickRequestNames.push(`getGoodsDetailFsAbt/${this.curData.goods_id}`)
        }
        TMG.triggerQuickRequests('pageGoodsDetail', {
          quickRequestNames,
          triggerParams: { from: 'detail' },
          throttle: true
        })
      }
      let imgRatio = this.imgRatio
      if (!imgRatio) {
        imgRatio = await this.getMainImgRatio()
      }
      url = `${url}${url.indexOf('?') === -1 ? '?' : '&'}imgRatio=${imgRatio}`

      try {
        if (getQueryString({ key: '__shouldUseSSR' }) === '__SSR__') {
          window.location.href = url
          return
        }
      } catch (err) {
        console.error(err)
      }


      // 存图片给详情使用
      const lazyLoadSrc = this.getLazyloadSrc()
      addProductDetailBfCache(this.curData, lazyLoadSrc)

      // 点击商品项，透传事件
      this.$emit('clickItem', {
        ...this.curData,
        imgRatio,
        goodsUrl: url,
        index: this.index,
        target: this.$refs.productCardRef,
        scrollPos: $(window).scrollTop(), // 记录当前点击商品项的scrollTop
        clickPosition: from ?? ''
      })

      if (!this.config.disableMainimgJump) {
        if (this.$route) {
          this.$route.meta.isForward = true
          this.config.spaRouterJumpType == 'replace' ? this.$router.replace(url) : this.$router.push(url)
        } else {
          // fix: 跳转太快，埋点发送有可能被取消，下一个event loop再跳转
          setTimeout(() => {
            this.config.spaRouterJumpType == 'replace' ? window.location.replace(url) : window.location.href = url
          }, 100)
        }

        this.reportJumpDetailMetric()
      }

      // trigger点击埋点
      if (this.delayClickAnalysis || from == 'soldOut') {
        if (this.delayClickAnalysis) {
          await nextTick()
        }

        clickProductItem({
          itemDAEventClickId: this.config.itemDAEventClickId || '2-3-1',
          target: this.$refs.productCardRef
        })
      }
    },

    // 上报跳转商详的监控指标
    reportJumpDetailMetric() {
      const pageName = this.config?.commonParams?.pageName
      if (!this.reportMetrics?.jumpDetail || !pageName) return

      reportMetricCount({
        metricName: 'click_to_detail_total',
        tags: { page: pageName, ...(this.reportMetrics.tags || {}) },
        message: 'Number of jump detail'
      })
    },

    getLazyloadSrc() {
      const { LAZY_IMG_SOLID_COLOR = '' } = this.constantData || {}
      let lazyLoadSrc = ''
      try {
        const imgElement = this.$el.querySelector('.product-card__main-img')
        lazyLoadSrc = imgElement.getAttribute('src') || ''
        if (lazyLoadSrc === LAZY_IMG_SOLID_COLOR) {
          return ''
        }
      } catch (err) {
        return ''
      }

      return lazyLoadSrc
    },

    disableJumpDetail(e) {
      // 编辑状态选中商品
      // 单列左边选择不阻止clickItem放出去
      if (this.showSelect && this.selectPosition !== 'mainLeft') {
        e.stopPropagation() // 阻止冒泡触发埋点

        // 聚焦 select checkbox
        if (this.focusSelectRange && this.isFocusCheckbox) {
          this.isFocusCheckbox = false
          this.handleSelect()
          return true
        }

        if (this.focusSelectRange && !this.isFocusCheckbox) {
          return false
        }

        this.handleSelect()
        return true
      }
    },

    async handleGoodsUrl(params, ignoreImgRatio = false) {
      // const transformSrc = this.transformImg({ img: this.getMainImg })
      const {
        goods_url_name = 'product',
        goods_id,
        cat_id,
        mall_code,
        sku_code,
      } = params

      // const transformSrc = this.$refs.productCardRef?.querySelector('.crop-image-container__img')?.getAttribute('src')
      const transformSrc = getRealShowImg({ item: this.curData, lazyLoadSrc: this.getLazyloadSrc() }).imgSrc


      const productDetailUrl = await getProductDetailUrl({
        imgSrc: transformSrc,
        goods_url_name,
        ignoreImgRatio,
        goods_id,
        cat_id,
        mall_code,
        urlQuery: {
          skucode: sku_code,
          ...this.config.urlQuery,
        },
        langPath: this.constantData.langPath,
      })

      return productDetailUrl
    },

    handleSelect() {
      if (this.selectDisable) return

      this.$emit('select', { item: this.curData, selected: !this.selectStatus })
    },

    touchstart() {
      this.longClickTimer = setTimeout(() => {
        this.longClick()
      }, this.config.longClickTime || 600)
    },

    touchmove() {
      clearTimeout(this.longClickTimer)
      this.longClickTimer = null
    },

    touchend(e) {
      if (this.emitLongClick) {
        e.preventDefault()
        this.emitLongClick = false
      }
      clearTimeout(this.longClickTimer)
      return false
    },

    longClick() {
      this.emitLongClick = true
      this.emitEvent('longClick')
    },

    handleClickMoreBtn() {
      this.emitEvent('clickMoreBtn')
    },

    emitEvent(event) {
      const { showCategoryViewStyle = false } = this.config
      if (this.isSoldOut && !this.showSimilarPopup && !showCategoryViewStyle) return

      let isShow = false
      // 增加实时反馈弹窗和负反馈的冲撞处理逻辑, 如果存在反馈弹窗，无论在哪触发都不允许出现负反馈（产品期望）
      let hasSlots = this.$slots?.imgBottom?.() || this.$slots?.cardBottomTop?.()
      let hasFeedBackPop = hasSlots?.[0] && hasSlots[0]?.elm?.childNodes?.length
      if (this.hasFeedback && !hasFeedBackPop) {
        const curStatus = !this.status.showFeedback
        isShow = this.status.showFeedback = curStatus

        // hasFeedback 负反馈用到了 imgRatio 在展示的时候才加载图片比例
        if (!this.imgRatio) {
          this.getMainImgRatio()
        }
      } else if (this.showOperatinPanel) {
        const curStatus = !this.status.showOperationPanel
        if ((event == 'longClick' && !this.config.showSelectOnLongClick) || event == 'clickMoreBtn') {
          this.status.showOperationPanel = curStatus
          this.soldoutOperationPanelShow = curStatus
        }
        isShow = curStatus
      } else if (showCategoryViewStyle) {
        const curStatus = !this.soldoutOperationPanelShow
        this.soldoutOperationPanelShow = curStatus
        this.status.showOperationPanel = curStatus
        isShow = curStatus
      }

      this.$emit(event, { index: this.index, isShow, item: this.curData })
    },

    hideSelf() {
      this.status.showSelf = false
    },
    childComponentSetAttrForAnalysis(opstions) {
      this.setAttrForAnalysis((el) => {
        el.setAttribute(opstions.key, opstions.analysisValue)
      })

    },
    handleExposeSellingPoint(text) {
      this.setAttrForAnalysis((el) => {
        el.setAttribute('data-selling-point', text)
      })
    },
    handleShowCccTspBadge(isShow) {
      const badges = []
      if (this.imgBottomLeft?.code) badges.push(this.imgBottomLeft.code)
      if (this.goodsBottom?.code && isShow) badges.push(this.goodsBottom.code)

      this.setAttrForAnalysis((el) => {
        const badges = []
        const dataBadges = el.getAttribute('data-badges')
        const showBadges = badges.join('|')
        if (dataBadges) badges.push(dataBadges)
        if (showBadges) badges.push(showBadges)
        el.setAttribute('data-badges', badges.join('|'))
      })
    },
    handleSetAddCartType(type) {
      this.setAttrForAnalysis((el) => {
        el.setAttribute('data-add-cart-type', type)
      })
    },
    clearAnalysisWaitingQueue() {
      if (!this.analysisWaitingQueue?.length) return
      this.analysisWaitingQueue.forEach((fun) => {
        fun()
      })
      this.analysisWaitingQueue = []
    },
    setAttrForAnalysis(fn) {
      const setElAttr = (el, isMainCtn = true) => {
        typeof fn === 'function' && fn(el, isMainCtn)
      }

      if (this.client) {
        setElAttr(this.$refs.productCardRef)
        
        // 清空埋点队列
        this.clearAnalysisWaitingQueue()
      } else {
        if (!this.analysisWaitingQueue) {
          this.analysisWaitingQueue = []
        }
        this.analysisWaitingQueue.push(() => {
          setElAttr(this.$refs.productCardRef)
        })
      }
      // const nameCtn = this.$el.querySelector('.product-item__name-wrapper')
      // nameCtn && setElAttr(nameCtn, false)
    },
    async handleOpenQuickAdd() {
      if (!this.imgRatio) {
        await this.getMainImgRatio()
      }

      this.reportAddBagMetric()
      
      this.$emit('openQuickAdd', {
        item: { ...this.curData, goods_img: getRealShowImg({ item: this.curData, lazyLoadSrc: this.getLazyloadSrc() }).imgSrc },
        index: this.index,
        target: this.$refs.productCardRef,
        imgRatio: this.imgRatio
      })

      // 埋点
      !this.config.useOwnClickAddBagAnalysis && clickAddBagAna({ target: this.$refs.productCardRef })
    },
    handleClickCheckout() {
      this.$emit('clickCheckout', { goods_sn: this.curData?.goods_sn || '' })

      !this.config.useOwnClickAddBagAnalysis && clickAddBagAna({ target: this.$refs.productCardRef })
    },
    openBuyBoxDrawer () { 
      this.$emit('openBuyBoxDrawer', {
        cat_id: this.curData?.cat_id || '',
        goods_id: this.curData?.goods_id || '',
        goods_sn: this.curData?.goods_sn || '',
        mall_code: this.curData?.mall_code || '',
      })
    },
    async getMainImgRatio() {
     
      // const transformSrc = this.transformImg({ img: this.getMainImg })

      // const imgSrc = this.$refs.productCardRef?.querySelector('.crop-image-container__img')?.getAttribute('src')
      const imgSrc = getRealShowImg({ item: this.curData, lazyLoadSrc: this.getLazyloadSrc() }).imgSrc
      return this.imgRatio = await getImageRatio(imgSrc)
    },
    // 上报点击加车按钮监控指标
    reportAddBagMetric() {
      const pageName = this.config?.commonParams?.pageName

      if (!this.reportMetrics?.addBag || !pageName) return

      reportMetricCount({
        metricName: 'click_to_addcart_total',
        tags: { page: pageName, ...(this.reportMetrics.tags || {}) },
        message: 'Number of click add bag button',
      })
    },
    getLabelCarousel,
    // 计算checkout按钮宽度
    checkoutEllipsis() {
      const pricesInfo = this.$refs.productCardRef?.querySelector('.product-card__prices-info')
      const priceWrapper = this.$refs.productCardRef?.querySelector('.bottom-wrapper__price-wrapper')
      const checkoutBtn = this.$refs.productCardRef?.querySelector('.product-card__add-btn')

      if (!pricesInfo || !priceWrapper || !checkoutBtn) return

      const gap = 12
      const checkoutMaxWidth = ~~(priceWrapper.getBoundingClientRect().width - pricesInfo.getBoundingClientRect().width - gap)
      checkoutBtn.style['maxWidth'] = `${checkoutMaxWidth}px`
    }
  },
}
