import { getQueryString } from '@shein/common-function'
import { abtservice } from 'public/src/services/abt'
import { searchWordsGlobalAnalysis } from 'public/src/pages/common/biz_helper/gb_sw_common'
// eslint-disable-next-line @shein-aidc/abt/abt
export const AB_TEST = () => abtservice.getUserAbtResultForAnalysis({ posKeys: 'SearchDefaultNew,SearchHotGD,SearchHotNew,PicSearch,NewPicSearch' }).sa

function sliceWord(word, type) {
  if (typeof type === 'undefined') {
    type = window?.SaPageInfo?.page_param?.result_type ?? 2
  }
  return type === 2 ? (word ?? '').slice(0, 150) : word
}
// 埋点要求的字段名是entrancetype（小写）, url携带的entranceType（大写）
function getEntrancetypeVal() {
  return window?.SaPageInfo?.page_param?.entrancetype || getQueryString({ key: 'entranceType' }) || '-'
}

// 联想词的人群标签埋点数据
function buildAssociationGroupTap(associationWord) {
  if(associationWord.groupTags?.length) {
    return associationWord.groupTags.map(tag => { 
      return `label_${tag.tagName}-${tag.tagId}-${tag.tagWordId}` 
    }).join('|')
  }
  return associationWord.wordTagType || 'common'
}

/**
 * 搜索埋点玩法searchInput
 *1 expose_search  落地曝光, 上报搜索框(内)内容 --e.g.原词/默认词全组
 *2 click_search  点击搜索框, 上报搜索框(内)内容 --e.g.原词/默认词当前轮更词
 *3 expose_top_site_search  抽屉内容曝光
 *4 click_top_site_search  抽屉内容点击 -- 修改 doSearch
 */
const analysis = {
  buildResultContent({ currentWord, correction, placeholder, defaultWords, historyWords = [], hotImages = [], associationWords = { word: [], guessWords: [] }, isCatNameSearchWrods, localIndex }, activityName) {
    let items = []
    // 当 currentWord 有值时，处理并添加到数组
    if (currentWord) {
      items.push(`${isCatNameSearchWrods ? 3 : 2}\`${sliceWord(currentWord)}\`1\`-\`-\`-\`common\`-\`-`)
    } else if (activityName === 'click_search' && placeholder?.word) {
      // 点击底纹词
      const index = defaultWords.findIndex( i => i.word === placeholder.word)
      return `3\`${placeholder.word}\`${(index + 1)}\`${placeholder.type || '-'}\`-\`${placeholder.wordLabel?.labelType || '-'}\`${placeholder.wordTagType || 'common'}\`${placeholder.word_id || '-'}\`-`
    } else {
      // 处理默认词或者占位词
      let defaultOrPlaceolderItems = (defaultWords || (placeholder ? [placeholder] : []))
        .filter(i => !!i?.word)
        // 词类型`词内容`排序号`策略名`New标签`标签类型`词细分类型
        .map((item, index) => `3\`${item?.word}\`${(localIndex + 1) || (index + 1)}\`${item?.type || '-'}\`-\`${item?.wordLabel?.labelType || '-'}\`${item?.wordTagType || 'common'}\`${item.word_id || '-'}\`-`)
      items = items?.concat?.(defaultOrPlaceolderItems)
    }

    // 处理历史词
    let historyItems = historyWords.map((item, index) => `5\`${item.word}\`${index + 1}\`-\`-\`-\`${item?.wordTagType || 'common'}\`-\`-`)
    items = items.concat(historyItems)

    // 处理热词
    let hotImageItems = hotImages.map((item, index) => `${item.from === 'ccc' ? 9 : 6}\`${item.word}\`${index + 1}\`${item.type || '-'}\`-\`${item.wordLabel?.labelType || '-'}\`${item?.wordTagType || 'common'}\`${item.word_id || '-'}\`-`)
    items = items.concat(hotImageItems)

    // 处理纠错词
    if (correction) {
      items.push(`8\`${correction}\`1\`-\`-\`-\`common\`-\`-`)
    }

    // 处理联想词
    let associationGuessItems = (associationWords.guessWords || []).map((item, index) => `23\`${item.word}\`${index + 1}\`-\`-\`-\`-\`${item.word_id || '-'}\`1`)
    let associationWordItems = associationWords.word.map((item, index) => `${correction ? 8 : 4}\`${item.keywords}\`${(correction ? 1 : 0) + index + 1}\`${'-'}\`${'-'}\`${item.tag || '-'}\`${buildAssociationGroupTap(item)}\`${item.word_id || '-'}\`${item.imgUrl ? 1 : 0 }`)
    items = items.concat(associationGuessItems, associationWordItems)
    let finalString = items.join()

    return finalString
  },
  buildIdentifier({ currentWord, correction, placeholder, defaultWords, historyWords = [], hotImages = [], associationWords = { word: [] }, isCatNameSearchWrods }, activityName) {
    // {st: 2,  // 类型 sc: '', // 原词 sr: 0, // 纠错词 ps: 0 // 位置 }
    const arr = []
    // 搜索词
    if (currentWord) {
      arr.push({ st: `${isCatNameSearchWrods ? 3 : 2}`, sc: sliceWord(currentWord), sr: 0, ps: 1 })
    } else if (activityName === 'click_search' && placeholder?.word) {
      // 点击底纹词
      const index = defaultWords.findIndex( i => i.word === placeholder.word)
      return `st=3\`sc=${placeholder.word}\`sr=0\`ps=${index + 1}`
    } else {
      // 默认词和占位词
      const words = defaultWords || (placeholder ? [placeholder] : [])
      words
        .filter(item => !!item?.word)
        .forEach((item, index) => {
          arr.push({ st: 3, sc: item?.word, ps: index + 1, sr: 0 })
        })
    }
    // 历史词
    historyWords.forEach((item, index) => {
      arr.push({ st: 5, sc: item.word, ps: index + 1, sr: 0 })
    })
    // 热词
    hotImages.forEach((item, index) => {
      arr.push({ st: 6, sc: item.word, ps: index + 1, sr: 0 })
    })
    // 纠错次
    if (correction) {
      arr.push({ st: 8, sc: currentWord, ps: 1, sr: correction })
    }
    // 联想词
    associationWords.word.forEach((item, index) => {
      arr.push({ st: correction ? 8 : 4, sc: item.keywords, ps: (correction ? 1 : 0) + index + 1, sr: 0 })
    })
    
    return arr.map(({ st, sc, sr, ps }) => `st=${st}\`sc=${sc}\`sr=${sr}\`ps=${ps}`).join(',')
  },
  searchInput(activity_name, payload, options) {
    const trace_id = payload.associationWords?.trace_id ||
      payload.placeholder?.trace_id || (payload.hotImages || [])[0]?.trace_id || '-'
    const data = {
      activity_name,
      activity_param: {
        search_content: sliceWord(payload.currentWord) || payload.placeholder?.word || '',
        result_content: this.buildResultContent(payload, activity_name),
        abtest: AB_TEST(),
        crowd_id: payload.crowdId || payload.placeholder?.crowdId || 0,
        src_module: payload.src_module || 'search',
        src_identifier: this.buildIdentifier(payload, activity_name),
        category_id: window?.SaPageInfo?.page_param?.category_id || '',
        result_type: window?.SaPageInfo?.page_param?.result_type || '',
        trace_id,
        ...options,
      }
    }
    if(['click_search'].includes(activity_name)) {
      searchWordsGlobalAnalysis.set({
        result_content: data?.activity_param?.result_content || '-'
      })
    }
    sa('send', data)
  },
  doSearch({ keyword = '', searchParamsRecord, result_type = 2, data: { position = 1, type, tag = '' }, crowdId, originWord, pageType, trend_list_content = '', wordTagType = 'common', trendWordData = '', wordExtraReport, reportExtendData, actionId, resultContent }) {
    if (!keyword || !result_type) throw new Error('search click analysis error')
    const srcModuleMap = {
      all: 'search',
      store: 'store_search',
      brand: 'chanel_search',
      pageTrend: 'trend_search'
    }
    const isOriginWordExtend = reportExtendData?.type === 'originWordIdAndAssociateTraceId' && result_type === 2
    const isAssocionWords = [4, 8].includes(result_type) 
    // trendWordData 趋势词预搜页 趋势榜相关埋点 词类型`词内容`排序号`策略名：无`New标签`标签类型`词细分类型的枚举值
    const data = {
      activity_name: 'click_top_site_search',
      activity_param: {
        trace_id: isOriginWordExtend && (reportExtendData.inputWord === keyword) ? reportExtendData.associationTraceId || '-' : searchParamsRecord?.trace_id || '-',
        search_content: sliceWord(keyword, result_type),
        result_content: resultContent ? resultContent : trendWordData ? trendWordData : `${result_type}\`${sliceWord(keyword, result_type)}\`${position}\`${type || '-'}\`${'-'}\`${tag || '-'}\`${wordTagType}\`${isOriginWordExtend ? (reportExtendData.inputWord === keyword ? reportExtendData.inputWordId || '-' : '-') : (searchParamsRecord?.word_id || '-')}\`${isAssocionWords ? wordExtraReport?.hasImg || 0 : [23].includes(result_type) ? 1 : '-'}`,
        abtest: AB_TEST(),
        crowd_id: crowdId || 0,
        src_module: srcModuleMap[pageType] ?? 'search',
        src_identifier: `st=${result_type}\`sc=${sliceWord(originWord, result_type)}\`sr=${result_type == 8 ? keyword : '0'}\`ps=${position}`,
        trend_list_content,
        entrancetype: getEntrancetypeVal(),
        ...(actionId ? { _actionId: actionId } : {}),
      },
      page_name: 'page_pre_search',
    }
    searchWordsGlobalAnalysis.set({
      result_content: data?.activity_param?.result_content || '-'
    })

    sa('send', data)
  },
  // 全站搜索入口曝光
  exposeSearch(payload) {
    const sendSa = () => {
      if (payload) {
        if (payload?.type === 'pageGoodsDetail') {
          sa('send', {
            activity_name: 'expose_search',
            activity_param: {
              abtest: AB_TEST(),
              Searchboxform: 3,
              entrancetype: getEntrancetypeVal(),
              result_content: '-'
            }
          })
        } else {
          this.searchInput('expose_search', payload, { Searchboxform: 2, entrancetype: getEntrancetypeVal(), })
        }
      } else {
        // window.argResultContent = '-'
        sa('send', {
          activity_name: 'expose_search',
          activity_param: {
            abtest: AB_TEST(),
            Searchboxform: 1,
            entrancetype: getEntrancetypeVal(),
            result_content: '-'
          }
        })
      }
    }

    // 目前搜索结果页会延迟发送pv，导致曝光页面名称page_name还是上一个页面，所以保证搜索结果页pv事件发了之后再去发曝光事件
    if (location.pathname.includes('/pdsearch/') && (window?.SaPageInfo.page_name !== 'page_search')) {
      appEventCenter.once('pageOnload', () => {
        sendSa()
      })
    } else {
      sendSa()
    }
  },
  // 全站搜索入口点击
  clickSearch(payload) {
    if(payload?.type === 'clickBackBtn') {
      // window.argResultContent = '-'
    }
    if (payload?.type === 'clickBackBtn') {
      return sa('send', {
        activity_name: 'click_search',
        activity_param: {
          abtest: AB_TEST(),
          Searchboxform: 1,
          entrancetype: getEntrancetypeVal(),
          result_content: '-'
        }
      })
    }
    if (payload?.type === 'pageGoodsDetail') {
      return sa('send', {
        activity_name: 'click_search',
        activity_param: {
          abtest: AB_TEST(),
          Searchboxform: 3,
          entrancetype: getEntrancetypeVal(),
          result_content: '-'
        }
      })
    }
    return this.searchInput('click_search', payload, { Searchboxform: 2, entrancetype: getEntrancetypeVal(), })
  },
  // 门店搜索入口曝光
  exposeStoreSearch(payload) {
    sa('send', {
      activity_name: 'expose_store_search',
      activity_param: {
        abtest: AB_TEST(),
        Searchboxform: payload.Searchboxform,
        search_content: payload.search_content,
        result_content: payload?.result_content || '',
        src_module: payload?.src_module || '',
        src_identifier: payload?.src_identifier || ''
      }
    })
  },
  // 门店搜索搜索框入口点击
  clickStoreSearch(payload) {
    sa('send', {
      activity_name: 'click_store_search',
      activity_param: {
        abtest: AB_TEST(),
        Searchboxform: payload.search_box_form,
        search_content: payload.search_content
      }
    })
  },
  // 门店搜索搜索按钮点击
  clickStoreSearchIcon(payload) {
    sa('send', {
      activity_name: 'click_icon_search',
      activity_param: {
        abtest: AB_TEST(),
        Searchboxform: payload.search_box_form,
        search_content: payload.search_content
      }
    })
  },
  // 专题搜索入口曝光
  exposeBrandSearch(payload) {
    sa('send', {
      activity_name: 'expose_chanel_search',
      activity_param: {
        abtest: AB_TEST(),
        Searchboxform: payload.Searchboxform,
        search_content: payload.search_content
      }
    })
  },
  // 专题搜索入口点击
  clickBrandSearch(payload) {
    sa('send', {
      activity_name: 'click_chanel_search',
      activity_param: {
        abtest: AB_TEST(),
        Searchboxform: payload.search_box_form,
        search_content: payload.search_content
      }
    })
  },
  clickClearBtn() {
    sa('send', {
      activity_name: 'click_search_clear'
    })
  },
  clickClearAll() {
    sa('send', {
      activity_name: 'click_search_clear_all'
    })
  },
  clickDelete() {
    sa('send', {
      activity_name: 'click_search_clear_history'
    })
  },
  clickPreSearchSimilarTitle() {
    sa('send', {
      activity_name: 'click_search_similar_title',
      activity_param: {
        activity_from: 'presearch_similar',
        src_module: 'presearch_similar',
        src_identifier: '-'
      }
    })
  },
  clickPreSearchSimilarViewMore() {
    sa('send', {
      activity_name: 'click_search_similar_viewmore',
      activity_param: {
        activity_from: 'presearch_similar',
        src_module: 'presearch_similar',
        src_identifier: '-'
      }
    })
  }
}
export default analysis
